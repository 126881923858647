const Theme = {

    // fonts
    primaryFont: "'Nunito Sans', sans-serif",

    // colors
    primaryColor: "#000000",
    secondaryColor: "#FFFFFF",
    borderBottomColor: "#E43D5A",
    GreyBackgroundColor: "#F7F7F7",
    LightBlueColor: "#9EA5EE",
    externalLinkColor: "#646464",
    leftSidebarLineDivider: "#979797",

    // font size 
    StatisticNumberSize: "80px",
    headingSize: "32px",
    subHeadingSize: "24px",
    StatisticTextSize: "22px",
    FooterLinkSize: "18px",
    subTextSize: "17px",
    headerLinkSize: "16px",
    upreachNameHeader: "15px",
    externalLinkSize: "14px",
    userInfoTextSize: "12px",
    "fontSize-20": "20px",
    "fontSize-21": "21px",
    "fontSize-22": "22px",
    "fontSize-28": "28px",

    // font weight
    StatisticNumberBold: "900",
    headingBold: "800",
    footerLinkBold: "600",
    textBold: "500",

    // line height 
    footerLinkLineHeight: "22px",
    subTextLineHeight: "27px",
    StatisticTextLineHeight: "30px",
    h1LineHeight: "43px",
    h2LineHeight: "50px",
    StatisticNumberLineHeight: "109px",
    'line-height-20' : '20px',
    'line-height-24' : '24px',
    'line-height-26' : '26px',
    'line-height-38' : '38px',

    // For spacing (Margin / Padding) in px
    'pixel-10': '10px',
    'pixel-20': '20px',
    'pixel-30': '30px',
    'pixel-40': '40px',
    'pixel-50': '50px',
    'pixel-100': '100px',

    // For spacing (Margin / Padding) in percentage
    'percent-5': '5%',
    'percent-10': '10%',
    'percent-15': '15%',
    'percent-20': '20%',
    'percent-25': '25%',
    'percent-30': '30%',
    'percent-40': '40%',
    'percent-50': '50%',
    'percent-90': '90%',
    'percent-100': '100%',

    // view width and view height
    'vh-100': '100vh',
    'vw-100': '100vw',

    // Zero 
    zero: "0",
}

export default Theme;