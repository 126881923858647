import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../images/logo.svg";
import { Link } from "gatsby";

const StyledWrapper = styled.div`
  .external-links {
    background-color: ${props => props.theme["borderBottomColor"]};
    text-align: center;
  }
  .header-external-links {
    margin-top: ${props => props.theme["pixel-20"]};
    font-size: 12px;
    color: ${props => props.theme["externalLinkColor"]};
    font-weight: normal;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline #e43d5a;
    }
  }

  .transform-sm {
    text-transform: unset;
  }

  .border-right {
    border-right: 1px solid ${props => props.theme["primaryColor"]} !important;
  }

  .upreach {
    color: ${props => props.theme["secondaryColor"]};
    font-size: ${props => props.theme["upreachNameHeader"]};
    font-weight: ${props => props.theme["footerLinkBold"]};
  }

  .grid-upper-container {
    display: grid !important;
    grid-template-columns: ${props => props.theme["percent-25"]} auto auto auto auto auto;
  }

  .grid-lower-container {
    display: grid !important;
    grid-template-columns: ${props => props.theme["percent-25"]} auto;
  }

  .internal-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: ${props => props.theme["headerLinkSize"]};
    font-weight: ${props => props.theme["headingBold"]};
  }

  .internal-links-a-tag {
    color: ${props => props.theme["primaryColor"]};
    position: relative;
    top: 5px;
    width: fit-content;
    margin-left: 100px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.5s;
    text-decoration: none;
  }

  .internal-links-a-tag:hover {
    border-bottom: 2px solid #e43d5a;
  }

  .active {
    border-bottom: 2px solid #e43d5a;
  }

  .header-mobile-desktop-visibility {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .sticky-nav {
    background-color: white;
    width: 100%;
    z-index: 9;
  }

  .upreach-transforming {
    position: relative;
    top: 15px;
  }

  .upreach-program {
    position: relative;
    left: -5px;
  }

  .main__logo {
    max-width: 200px;
  }
`;

const Header = () => {
  const [fixed, setFixed] = React.useState(80);
  const [position, setPosition] = React.useState(null);

  const pixelValue = fixed + "px";

  React.useEffect(() => {
    window.addEventListener("scroll", e => {
      if (window.pageYOffset > 100) {
        setFixed(0);
        setPosition("fixed");
      }
      if (window.pageYOffset < 100) {
        setFixed(80);
        setPosition("unset");
      }
    });
  }, []);

  return (
    <StyledWrapper>
      <div className="header-mobile-desktop-visibility">
        <div className="grid-upper-container pb-3 external-links">
          <div className="item1">
            <div className="upreach-transforming">
              <p className="header-external-links upreach upreach-program mt-1">
                Contextualised Recruitment
              </p>
            </div>
          </div>
          <div className="item2 white-background">
            <Link target="_blank" to="https://upreach.org.uk">
              <p className="header-external-links border-right px-3">UPREACH</p>
            </Link>
          </div>
          <div className="item3 white-background">
            <Link target="_blank" to="http://socialmobilitynetwork.org.uk/">
              <p className="header-external-links border-right px-3">
                Social mobility network
              </p>
            </Link>
          </div>
          <div className="item4 white-background">
            <Link
              target="_blank"
              to="https://studentsocialmobilityawards.org.uk"
            >
              <p className="header-external-links border-right px-3">
                Student social mobility awards
              </p>
            </Link>
          </div>
          <div className="item5 white-background">
            <Link target="_blank" to="https://aspire.upreach.org.uk/">
              <p className="header-external-links border-right px-3">
                sixth form
              </p>
            </Link>
          </div>
          <div className="item6 white-background">
            <Link target="_blank" to="https://getemployable.org">
              <p className="header-external-links px-3">
                graduate employability framework
              </p>
            </Link>
          </div>
        </div>
        <div
          className="sticky-nav"
          style={{ top: pixelValue, position: position }}
        >
          <div className="grid-lower-container py-4">
            <div className="item1">
              <Link to="/">
                <img className="mx-5 d-flex main__logo" src={Logo} alt="" />
              </Link>
            </div>
            <div className="internal-links pr-1 mx-5">
              <Link
                activeClassName="active"
                partiallyActive={true}
                className="internal-links-a-tag"
                to="/students"
              >
                Students
              </Link>

              <Link
                activeClassName="active"
                partiallyActive={true}
                className="internal-links-a-tag"
                to="/employers"
              >
                Employers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Header;
