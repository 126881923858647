import React from 'react'
import GlobalStyle from '../globalStyle';
import { ThemeProvider } from "styled-components";
import theme from '../theme';
import Header from './header';
import MobileHeader from './MobileHeader';
import Footer from './footer';
import { Helmet } from "react-helmet";
import favicon from '../images/favicon.svg'

function layout({children}) {
    return (
        <ThemeProvider theme={theme}>
          <Helmet>
            <link rel="stylesheet" type="text/css" charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
          <link rel="stylesheet" type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
          <meta property="og:description" content="upReach help disadvantaged students from across the UK to realise their potential. Our team of Programme Coordinators provide a highly personalised programme of support to each undergraduate student. Through successful partnerships with 45 top employers and universities, we offer Associates access to a comprehensive range of opportunities and activities to broaden their horizons, understand career pathways and develop the skills, networks and experiences needed for career success. We collaborate closely with other charities in our sector to maximise our joint impact. From working with 39 Associates in 2013, this year have supported over 1,800, while maintaining our personalised approach and expanding the support offered. In October 2019, upReach won the Charity of the Year Award (income < £1m) in the prestigious Charity Times Awards" />
          <meta property="og:image" content={favicon} />
          </Helmet>
          <GlobalStyle />
          <Header/>
          <MobileHeader/>
          <div>
            <main>{children}</main>
          </div>
          <Footer/>
        </ThemeProvider>
    )
}

export default layout
